export const useGlobal = () => {
  const isMobileMenuOpen = useState<boolean>("isMobileMenuOpen", () => false);

  // Menu
  const isShowWaveformEnabled = useState<boolean>(
    "isShowWaveformEnabled",
    () => true
  );
  const isScrollToCurrentSubtitleEnabled = useState<boolean>(
    "isScrollToCurrentSubtitleEnabled",
    () => true
  );

  const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
  };

  return {
    isMobileMenuOpen,
    toggleMobileMenu,
    isShowWaveformEnabled,
    isScrollToCurrentSubtitleEnabled,
  };
};
